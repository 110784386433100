import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import styles from "../components/ARWebBilling.module.css";

import "./../assets/css/custom.css";

import i18next from "../constants/i18n";
import { formatCurrency } from "../utils/utils";

let fontStyle1 = {
  temp1: {},
  temp2: {
    fontFamily: "GilroySemiBold !important",
  },
};

const ARWebBilling = (props) => {
  let {
    totalCartPrice,
    subTotal,
    discount,
    taxValue,
    tipValue,
    restTemplate,
    restData,
    orderType,
    serviceCharge,
    themeColors = {},
  } = props;

  const {
    checkoutCardsBgColor = null,
    checkoutBillingPriceFontSize = null,
    checkoutBillingTotalPriceFontSize = null,
    checkoutCardsFontColor = null,
  } = themeColors || {};

  const charges = restData?.charges?.map((charge) => {
    if (charge.isActive && charge.orderType == orderType)
      return {
        label: charge.name,
        value: charge.isPercentage
          ? (subTotal * charge.value) / 100
          : charge.value,
      };
  });
  let billingList = [
    {
      label: i18next.t("Subtotal"),
      value: subTotal,
    },
    ...charges,
  ];

  if (tipValue) {
    billingList.push({
      label: i18next.t("Tip"),
      value: tipValue,
    });
  }

  return (
    <Grid item mx={props.page == "checkout" ? 2 : 1} pb={3} mt={1.5}>
      <Box
        p={2}
        sx={{
          backgroundColor:
            (props.page === "checkout" && checkoutCardsBgColor) ??
            "transparent",
        }}
        className={styles.mainView}
      >
        {billingList.map((x, i) =>
          x?.value > 0 ? (
            <Box key={i} className={styles.listView} mb={1}>
              <Box className={styles.labelView}>
                <Typography
                  className={styles.labelText}
                  sx={{
                    color: `${checkoutCardsFontColor ?? "#000"} !important`,
                  }}
                >
                  {x.label}
                </Typography>
              </Box>
              <Typography
                className={styles.billVal}
                sx={{
                  fontSize: `${checkoutBillingPriceFontSize ?? "16"}px`,
                  color: `${checkoutCardsFontColor ?? "#000"} !important`,
                }}
              >
                {/* {currency} */}
                {formatCurrency(x.value)}
              </Typography>
            </Box>
          ) : null
        )}

        <Box className={styles.listView}>
          <Box className={styles.listLabelView}>
            <Typography
              className={styles.billTitle}
              sx={{
                ...fontStyle1[restTemplate],
                color: `${checkoutCardsFontColor ?? "#000"} !important`,
              }}
            >
              {i18next.t("Your bill")}
            </Typography>
          </Box>
          <Typography
            className={styles.totalVal}
            sx={{
              ...fontStyle1[restTemplate],
              color: `${checkoutCardsFontColor ?? "#000"} !important`,
              fontSize: `${checkoutBillingTotalPriceFontSize ?? "19"}px`,
            }}
          >
            {/* {currency} */}
            {formatCurrency(totalCartPrice)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default ARWebBilling;
