import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Loader from "../components/Loader";
import AppNextPrevBtnTemp2 from "./AppNextPrevBtnTemp2";
import ArBtn from "./ArBtn";
import MenuTypeFilter from "./MenuTypeFilter";
import { CategoryGrpList } from './CategoryGrpList';

import { ReactComponent as ShareIcon } from "../assets/images/share1.svg";
import { ReactComponent as WifiIcon } from "../assets/images/wifi.svg";

import BgImg from "../assets/images/bg2.png";

import {
  CATEGORY_GRP_DEFAULT_POS,
  CURRENCY_SYMBOL,
  MENU_TYPE3,
} from "../constants";
import { getRequiredModifierPrice } from "../constants/commonFunc";
import * as Api from "../api/app";
import "../assets/css/custom.css";

const TOP_VIEW_HEIGHT = 70;
const MENU_ITEM_HEIGHT = 52;
export const CATEGORY_LIST_HEIGHT = 100;

export let SIDEBAR_MENU_TOP = MENU_ITEM_HEIGHT + 14;
export let SIDEBAR_MENU_POSITION = 16;
export let SIDEBAR_ICON_SIZE = 46;

const symbol = CURRENCY_SYMBOL;

const RestaurantTemplate2 = (props) => {
  const {
    restaurantId,
    restaurantName,
    restaurantData,
    restaurantLogo,
    restTemplate,
    canActivateAr,
    viewArBtnTop,
    menuList,
    menuIndex,
    menuFullList,
    tableNumber,
    enterAframeAr,
    enterWebXrArMode,
    showPrevBtn,
    showNextBtn,
    vegNonveg,
    vrScreenModalVisible,
    menuTypeList,
    showCategoryGrp = false,
    categoryGrpPosition = CATEGORY_GRP_DEFAULT_POS,
    categoryGrpList = [],
  } = props;

  const { menuNameRef, progressCircleRef } = props;

  const menu = menuList?.[menuIndex];
  const currency = symbol?.[menu?.price?.currency];
  const itemPrice = (
    menu?.price?.value
      ? menu.price.value
      : getRequiredModifierPrice(menu?.modifiers, menuFullList)
  )?.toFixed(2);
  const menuType = MENU_TYPE3?.[menu?.extras?.menuType] ?? null;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handlerOnShareBtnClick = () => {
    props.onShareBtnClick(menu);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  
  // Add this where you handle categoryGrpList
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    props.onRestMenuNavigate([category]);

    // Add any additional logic for category selection
  };

  const NextPrevBtnView = () => {
    const {
      prevIcon = "",
      nextIcon = "",
      menuSectionBg = "",
    } = restaurantData?.themeColors || {};
    return (
      <AppNextPrevBtnTemp2
        enterAframeAr={enterAframeAr}
        enterWebXrArMode={enterWebXrArMode}
        menuIcon={
          restaurantData?.bottomMenuIcon ? restaurantData.bottomMenuIcon : null
        }
        menuList={menuList}
        menuIndex={menuIndex}
        locale={props.locale}
        showPrevBtn={showPrevBtn}
        showNextBtn={showNextBtn}
        prevIcon={prevIcon}
        nextIcon={nextIcon}
        menuSectionBg={menuSectionBg}
        categoryGrpPosition={categoryGrpPosition}
        showCategoryGrp={showCategoryGrp}
        onNextBtnClick={props.onNextBtnClick}
        onPrevBtnClick={props.onPrevBtnClick}
        onRestMenuNavigate={props.onRestMenuNavigate}
      />
    );
  };

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  useEffect(() => {
    if (restaurantData?._id) {
      getCategoryList(restaurantData?._id);
    }
  }, [restaurantData?._id]);

  const getCategoryList = async (restId) => {
    const filter = `?pageSize=30000&pageNum=1&filter_enabled=true`;
    const response = await Api.getCategoryList(restId, filter);
    if (response?.success) {
      const data = response?.data?.rows?.sort((a, b) => a?.order - b?.order) ?? [];
      setCategories(data);
      setFilteredCategories(data);
    }
  };

  const handleGroupSelect = (group) => {
    if (group) {
      // Navigate to menu list with selected group
      props.onRestMenuNavigate({
        selectedGroup: group,
        categories: group.categories
      });
    } else {
      props.onRestMenuNavigate({
        selectedGroup: selectedCategory,
        categories: [selectedCategory]
      });
    }
  };

  return (
    <>
      <img
        alt=""
        src={restaurantData?.arModelBg ? restaurantData.arModelBg : BgImg}
        slot="poster"
        style={{
          height: `calc(100vh - ${TOP_VIEW_HEIGHT}px - ${
            showCategoryGrp && categoryGrpPosition === CATEGORY_GRP_DEFAULT_POS
              ? 0
              : MENU_ITEM_HEIGHT
          }px)`,
          width: "100%",
          position: "absolute",
          bottom: 0,
          objectFit: "cover",
          objectPosition: "top",
        }}
      />
      <Grid
        dir={"ltr"}
        container
        item
        xs={12}
        px={2}
        direction="row"
        style={{
          ...styles.topMainView,
        }}
      >
        {restaurantData?.headerBg ? (
          <Box style={styles.headerBg}>
            <img
              src={restaurantData?.headerBg}
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover" }}
            />
          </Box>
        ) : null}
        {tableNumber ? (
          <Box sx={styles.tblNoView}>
            <Typography sx={styles.tableNo}>
              <span style={styles.tableNoLabel}>TABLE</span>
              <span style={styles.tableNoValue}>{tableNumber}</span>
            </Typography>
          </Box>
        ) : null}
        {/*<Box className="app-restaurant-bg-shade2" style={styles.bgShadeImage} />*/}
        {/*<Box style={styles.menuBottomShadeImage} />*/}
        {/*<Box style={styles.topView}>*/}
        {/*  {restaurantLogo ? (*/}
        {/*    <img alt="" src={restaurantLogo} style={styles.restImg} />*/}
        {/*  ) : null}*/}
        {/*</Box>*/}
      </Grid>
      <Grid item xs={12} sx={styles.contentView}>
        {props.modelAR}
        <Box
          style={styles.iconView}
          sx={{
            top: `${
              SIDEBAR_MENU_TOP +
              (showCategoryGrp && categoryGrpPosition === CATEGORY_GRP_DEFAULT_POS
                ? CATEGORY_LIST_HEIGHT
                : 0)
            }px`,
            right: props.locale == "ar" ? "unset" : 10,
            left: props.locale == "ar" ? 10 : "unset",
          }}
        >
          {restaurantData?.socialMedias
            .filter((x) => x?.icon)
            .map((x, i) => {
              let width = x?.data?.iconWidth ?? SIDEBAR_ICON_SIZE;
              let height = x?.data?.iconHeight ?? SIDEBAR_ICON_SIZE;
              return (
                <Box
                  key={i}
                  sx={{
                    ...styles.detailBtn,
                    width: `${width}px`,
                    height: `${height}px`,
                  }}
                  onClick={() =>
                    x?.data?.url
                      ? window.open(
                          x?.data?.url?.includes("https://")
                            ? x?.data?.url
                            : `https://${x?.data?.url}`,
                          "_blank"
                        )
                      : null
                  }
                >
                  <img src={x?.icon} width={"100%"} height={"100%"} />
                </Box>
              );
            })}
          {restaurantData?.wifiAvailable ? (
            <Box sx={styles.detailBtn} onClick={props.onWifiClick}>
              <WifiIcon width={"100%"} height={"100%"} />
            </Box>
          ) : null}
          <ArBtn
            canActivateAr={canActivateAr}
            restTemplate={restTemplate}
            restaurantId={restaurantId}
            userData={props.userData}
            setVrScreenModalVisible={props.setVrScreenModalVisible}
            setEnterAframeAr={props.setEnterAframeAr}
            onArIconClick={props.onArIconClick}
          />
          <Box
            sx={{
              ...styles.detailBtn,
            }}
            onClick={handlerOnShareBtnClick}
          >
            <ShareIcon width={"100%"} height={"100%"} />
          </Box>
        </Box>
        <div className="progress-loader" ref={progressCircleRef}>
          <Loader height={{ height: window.innerHeight }} />
        </div>
        {showCategoryGrp && (
          <Box
            sx={{
              ...styles.categoryGrpListView,
              ...(categoryGrpPosition !== CATEGORY_GRP_DEFAULT_POS
                ? { bottom: 0 }
                : { top: 0 }),
            }}
          >
            <CategoryGrpList
              list={restaurantData?.groupCategory || []}
              height={CATEGORY_LIST_HEIGHT}
              onCategorySelect={handleCategorySelect}
              onGroupSelect={handleGroupSelect}
            />
          </Box>
        )}
        {menuList.length > 0 ? (
          <Grid
            ref={menuNameRef}
            container
            item
            xs={12}
            direction="row"
            px={2}
            sx={styles.menuMainView}
            style={{
              top:
                showCategoryGrp && categoryGrpPosition === CATEGORY_GRP_DEFAULT_POS
                  ? CATEGORY_LIST_HEIGHT
                  : 0,
              backgroundColor: restaurantData?.menuItemFontBg
                ? restaurantData?.menuItemFontBg
                : "#2f5831",
            }}
          >
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                className="app-restaurant-menu-title"
                sx={styles.menuTitleView}
                style={{
                  color: restaurantData?.menuItemFontColor
                    ? restaurantData.menuItemFontColor
                    : "#FFF",
                }}
              >
                {capitalizeFirstLetter(menu?.name)}
              </Typography>
              <Box sx={{ display: "flex" }}>{menuType}</Box>
            </Grid>
            <Grid item xs={4} sx={styles.menuPriceMainView}>
              <Box sx={styles.menuPriceView} dir={"ltr"}>
                <Typography
                  sx={styles.menuPriceText}
                  style={{
                    color: restaurantData?.menuItemFontColor
                      ? restaurantData.menuItemFontColor
                      : "#FFF",
                  }}
                >
                  {currency}
                  {itemPrice}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 8,
            left: 10,
            top:
              SIDEBAR_MENU_TOP +
              (showCategoryGrp && categoryGrpPosition === CATEGORY_GRP_DEFAULT_POS
                ? CATEGORY_LIST_HEIGHT
                : 0),
          }}
        >
          {menuTypeList?.length ? (
            <MenuTypeFilter
              value={vegNonveg}
              list={menuTypeList}
              left={window.innerWidth <= 900 ? "10px !important" : 0}
              setValue={props.setVegNonveg}
            />
          ) : null}
        </Box>
        {enterWebXrArMode ? null : <NextPrevBtnView />}
      </Grid>
    </>
  );
};

export default RestaurantTemplate2;

const styles = {
  topMainView: {
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    height: TOP_VIEW_HEIGHT,
    flexWrap: "nowrap",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  topView: {
    width: "100%",
    height: "70%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  restImg: {
    height: "100%",
    width: 180,
    objectFit: "contain",
  },
  bgShadeImage: {
    backgroundColor: "rgb(0 0 0 / 70%)",
    width: "100%",
    height: TOP_VIEW_HEIGHT,
    position: "absolute",
    top: 0,
    left: 0,
    backdropFilter: "blur(10px)",
  },
  headerBg: {
    width: "100%",
    height: TOP_VIEW_HEIGHT,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#000",
  },
  tblNoView: {
    zIndex: 6,
  },
  tableNo: {
    color: "#FFF",
    fontFamily: "BauhausMedium",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tableNoLabel: {
    fontSize: 12,
  },
  tableNoValue: {
    fontSize: 14,
    lineHeight: 1.1,
  },
  // menuBottomShadeImage: {
  //   backgroundColor: "#d870255e",
  //   width: "100%",
  //   height: MENU_SHADE_HEIGHT,
  //   position: "absolute",
  //   bottom: 0,
  //   left: 0,
  // },
  backBtn: {
    marginRight: 10,
    zIndex: 3,
  },
  contentView: {
    position: "relative",
    height: window.innerHeight - TOP_VIEW_HEIGHT,
  },
  iconView: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5,
  },
  detailBtn: {
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    borderRadius: "50%",
    cursor: "pointer",
    overflow: "hidden",
    marginBottom: `${SIDEBAR_MENU_POSITION}px`,
  },
  categoryGrpListView: {
    position: "absolute",
    zIndex: 4,
  },
  menuMainView: {
    height: MENU_ITEM_HEIGHT,
    alignItems: "center",
    position: "absolute",
    zIndex: 4,
  },
  menuTitleView: {
    fontFamily: "BauhausMedium",
    fontSize: 17,
    lineHeight: 1.1,
    mr: 1,
  },
  menuPriceMainView: {
    display: "flex",
    justifyContent: "flex-end",
  },
  menuPriceView: {
    pl: 1.4,
    py: 0.6,
  },
  menuPriceText: {
    fontSize: 17,
    fontFamily: "BauhausMedium",
  },
};
