import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { ReactComponent as TickWithBorderIcon } from "../../../assets/images/icons/tick-with-border.svg";
import { formatCurrency } from "../../../utils/utils";

const MiniInvoiceWidget = ({
  orderData,
  primaryBgColor = null,
  primaryFontColor = null,
  secondaryBgColor = null,
  secondaryFontColor = null,
  tickIconBgColor = null,
}) => {
  return (
    <Box
      sx={{
        ...styles.miniInvoiceWidget,
        backgroundColor: primaryBgColor ?? "#95E7BB",
      }}
    >
      <Box
        sx={{
          ...styles.topView,
          "#tick-circle-path": {
            fill: primaryBgColor ?? "#95E7BB",
            stroke: tickIconBgColor ?? "#FFF",
          },
          "#tick-path": { fill: tickIconBgColor ?? "#FFF" },
        }}
      >
        <TickWithBorderIcon style={styles.tickIcon} />

        <Typography
          mt={2}
          sx={{ ...styles.thankyouText, color: primaryFontColor ?? "#212529" }}
        >
          Thank you for your order!
        </Typography>
        <Typography
          sx={{
            ...styles.orderPlacedText,
            color: primaryFontColor ?? "#212529",
          }}
        >
          Your order #{orderData?._id.toString().substr(-5)} has been
          placed.&lrm;
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: 2,
          mt: 6,
        }}
      >
        {/* <Box>
					<Typography sx={styles.defListLabel}>10 min</Typography>
					<Typography sx={styles.defListValue}>Est. Delivery</Typography>
				</Box> */}

        <Box>
          <Typography
            sx={{
              ...styles.defListLabel,
              color: primaryFontColor ?? "#212529",
            }}
          >
            x{orderData?.cart?.length}
          </Typography>
          <Typography
            sx={{
              ...styles.defListValue,
              color: primaryFontColor ?? "#212529",
            }}
          >
            Items ordered
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              ...styles.defListLabel,
              color: primaryFontColor ?? "#212529",
              fontSize: 24,
            }}
          >
            {orderData?.tableNumber}
          </Typography>
          <Typography
            sx={{
              ...styles.defListValue,
              color: primaryFontColor ?? "#212529",
              fontSize: 14,
            }}
          >
            Table No
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              ...styles.defListLabel,
              color: primaryFontColor ?? "#212529",
            }}
          >
            {formatCurrency(orderData?.totalAmount)}
          </Typography>
          <Typography
            sx={{
              ...styles.defListValue,
              color: primaryFontColor ?? "#212529",
            }}
          >
            Total
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "43px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: secondaryBgColor ?? "#B0EDCD",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Typography
          sx={{ ...styles.timeText, color: secondaryFontColor ?? "#595959" }}
        >
          Order placed at:{" "}
          {moment(orderData?.createdAt).format("hh:mm A   MMM D")}
        </Typography>
      </Box>
    </Box>
  );
};

export default MiniInvoiceWidget;

const styles = {
  miniInvoiceWidget: {
    borderRadius: "15px",
    mx: 1,
    mt: 6,
    height: "270px",
    position: "relative",
  },
  topView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  tickIcon: {
    position: "absolute",
    top: "-30px",
  },
  thankyouText: {
    fontSize: 24,
    fontFamily: "Inter",
    fontWeight: 700,
    textAlign: "center",
    margin: "45px 0 8px 0",
    padding: 0,
  },
  orderPlacedText: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: "center",
    margin: 0,
    padding: 0,
  },
  defListLabel: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
  },
  defListValue: {
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 400,
    opacity: 0.5,
    textAlign: "center",
  },
  sendEmailText: {
    color: "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  timeText: {
    fontSize: 12,
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: "center",
  },
};
