import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./MenuTypeFilter.module.css";

import { ReactComponent as MenuVegIcon } from "../assets/images/veg_icon.svg";
import { ReactComponent as MenuNonVegIcon } from "../assets/images/non_veg_icon.svg";

const MenuCategoryGroupFilter = (props) => {
    const { bgColor = "#FFF", fontColor = "#000" } = props;

    const theme = createTheme({
        components: {
            MuiPopover: {
                styleOverrides: {
                    root: {
                        "& .MuiPaper-root": {
                            backgroundColor: bgColor ?? "#FFF",
                        },
                        "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
                            props?.left
                                ? {
                                    left: `${props?.left}`,
                                }
                                : null,
                    },
                },
            },
        },
    });

    return (
        <FormControl variant="filled">
            <ThemeProvider theme={theme}>
                <Select
                    value={props.value}
                    onChange={(event) => props.setValue(event.target.value)}
                    className={styles.selectView}
                    sx={{
                        height: props?.height ? props?.height : 40,
                        "&.MuiInputBase-root": {
                            color: `${fontColor ?? "#000"} !important`,
                            backgroundColor: `${bgColor ?? "#FFF"} !important`,
                            "&:hover": {
                                backgroundColor: bgColor ?? "#FFF",
                            },
                            "&.Mui-focused": {
                                backgroundColor: bgColor ?? "#FFF",
                            },
                            "&:before, :after, :hover:not(.Mui-disabled):before": {
                                borderBottom: "0px !important",
                            },
                            "& .MuiSelect-select": {
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: bgColor ?? "#FFF",
                                py: 0,
                            },
                            "& .MuiSvgIcon-root": {
                                color: fontColor ?? "#000"
                            },
                        },
                    }}
                    py={1.3}
                    px={0.5}
                >
                    {props.list.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item}
                            className={styles.selectValue}
                            sx={{ color: fontColor }}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </ThemeProvider>
        </FormControl>
    );
};

export default MenuCategoryGroupFilter;
