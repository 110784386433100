import React from "react";
import Box from "@mui/material/Box";

import AppNextBtn from "./AppNextBtn2";
import AppPreviousBtn from "./AppPreviousBtn2";

import { ReactComponent as MenuIcon } from "../assets/images/menu2.svg";

import { CATEGORY_LIST_HEIGHT } from "./RestaurantTemplate2";
import { CATEGORY_GRP_DEFAULT_POS } from "../constants";

let WINDOW_HEIGHT = window.innerHeight;

const AppNextPrevBtnTemp2 = (props) => {
  const {
    enterAframeAr,
    enterWebXrArMode,
    showPrevBtn,
    showNextBtn,
    prevIcon,
    nextIcon,
    menuSectionBg,
    categoryGrpPosition = CATEGORY_GRP_DEFAULT_POS,
    showCategoryGrp = false
  } = props;

  const BtnView = () => {
    let posStyle = {};
    if (enterAframeAr) {
      posStyle = { bottom: 15 };
    }
    if (enterWebXrArMode) {
      posStyle = { top: WINDOW_HEIGHT + 40 };
    }

    return (
      <>
        <Box sx={{ ...styles.prevMainView, ...posStyle }}>
          {showPrevBtn ? (
            <AppPreviousBtn
              locale={props.locale}
              image={prevIcon}
              isCircle={false}
              onPrevBtnClick={props.onPrevBtnClick}
            />
          ) : null}
        </Box>
        {enterAframeAr || enterWebXrArMode ? null : (
          <Box
            style={{
              ...styles.menuBtnView,
              ...(props.menuIcon ? { width: 90, height: 90 } : null),
            }}
          >
            {props.menuIcon ? (
              <img
                src={props.menuIcon}
                width={"100%"}
                height={"100%"}
                onClick={() => props.onRestMenuNavigate()}
              />
            ) : (
              <MenuIcon
                width={"100%"}
                height={"100%"}
                onClick={() => props.onRestMenuNavigate()}
              />
            )}
          </Box>
        )}
        <Box sx={{ ...styles.nextMainView, ...posStyle }}>
          {showNextBtn ? (
            <AppNextBtn
              locale={props.locale}
              image={nextIcon}
              isCircle={false}
              onNextBtnClick={props.onNextBtnClick}
            />
          ) : null}
        </Box>
      </>
    );
  };

  if (enterAframeAr || enterWebXrArMode) {
    return <BtnView />;
  }

  return (
    <Box
      sx={{
        ...styles.btnMainView,
        bottom:
          (showCategoryGrp && categoryGrpPosition !== CATEGORY_GRP_DEFAULT_POS
            ? CATEGORY_LIST_HEIGHT
            : 0) + (menuSectionBg ? 10 : 30),
      }}
    >
      <Box className={"next-prev-btn-device-width"}>
        {menuSectionBg ? (
          <Box sx={styles.bottomView}>
            <img src={menuSectionBg} style={styles.imgStyle} />
            <BtnView />
          </Box>
        ) : (
          <Box sx={styles.btnView}>
            <Box sx={styles.btnView2}></Box>
            <BtnView />
            <Box sx={styles.btnView3}></Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppNextPrevBtnTemp2;

const styles = {
  btnMainView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 8,
  },
  bottomView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    minHeight: 80,
    width: "100%",
  },
  imgStyle: {
    width: "100%",
    height: "100%",
  },
  btnView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: 80,
    width: "100%",
    borderRadius: 6,
    border: "0.03px solid #00000030",
    background: "linear-gradient(#00000020, #94949420)",
  },
  btnView2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: 120,
    width: 120,
    borderRadius: "50%",
    border: "0.03px solid #00000030",
    backdropFilter: "blur(5px)",
    background: "linear-gradient(#00000020, #94949420)",
  },
  btnView3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    backdropFilter: "blur(5px)",
    borderRadius: 6,
  },
  prevMainView: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 8,
    left: 0,
    px: 2,
  },
  nextMainView: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 7,
    right: 0,
    px: 2,
  },
  menuBtnView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: 100,
    height: 100,
    borderRadius: "50%",
    overflow: "hidden",
    cursor: "pointer",
    zIndex: 7,
    px: 2,
  },
};
