import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "@google/model-viewer";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";

import { connect } from "react-redux";
import {
  updateCartItems,
  updateLastViewedMenu,
  updateRestaurantDetail,
} from "../redux/actions/restaurantDataActions";

import {
  updateCategoryList,
  updateMenuList,
  updateModelList,
  updateRestData,
  updateTableData,
} from "../redux/actions/apiDataActions";

import * as Api from "../api/app";

import Loader from "../components/Loader";
import ARWebMenuModal from "../components/ARWebMenuModal";
import ARWebAllergensNutritions from "../components/ARWebAllergensNutritions";

import RestaurantTemplate1 from "../components/RestaurantTemplate1";
import RestaurantTemplate2 from "../components/RestaurantTemplate2";
import AppNextPrevBtnTemp1 from "../components/AppNextPrevBtnTemp1";
import AppNextPrevBtnTemp2 from "../components/AppNextPrevBtnTemp2";
import VrScreenModal from "../components/VrScreenModal";

import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

import BgImg1 from "../assets/images/bg.jpg";
import BgImg2 from "../assets/images/bg2.png";

import "../assets/css/custom.css";

import {
  APP_TYPE_BOTH,
  APP_TYPE_GOPOSH,
  CATEGORY_GRP_DEFAULT_POS,
  TABLE,
  TEMP1REST,
} from "../constants";

import { initAnalytics, trackEvent, MENU_VIEW } from "../constants/analytics";
import {
  addMenuItemToCart,
  checkRestIsClosed,
  filterMenuType,
} from "../constants/commonFunc";

import styles1 from "./Restaurants.module.css";

let page_size = 300000;

let BgImg = {
  temp1: BgImg1,
  temp2: BgImg2,
};

// let url = window.location.href;
// let parts = url.split("/");
// if (parts.length >= 3) {
//   let part = parts[2].split(".");
//   if (part.length) {
//     let subdomain = part[0];
//     if (subdomain == "menu") {
//       SHOW_ZOMATO_SWIGGY_ICON = false;
//     }
//   }
// }

export function checkRestTemplateData(restId) {
  let TEMPLATE = "temp2";

  if (TEMP1REST.includes(restId)) {
    TEMPLATE = "temp1";
  }

  return { TEMPLATE };
}

initAnalytics();

const getTableNumberParam = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  return params.get("table");
};

const getMenuIdParam = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  return params.get("menu_id");
};

const categoryGrpList = [
  {
    name: "Menu",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/86712e031520839ce4509e8e9231b7c3.jpeg",
  },
  {
    name: "Drinks",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/da39cdd6cd2dc82b8372c346f9911860.jpg",
  },
  {
    name: "Menu2",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/86712e031520839ce4509e8e9231b7c3.jpeg",
  },
  {
    name: "Menu3",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/da39cdd6cd2dc82b8372c346f9911860.jpg",
  },
  {
    name: "Menu4",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/86712e031520839ce4509e8e9231b7c3.jpeg",
  },
  {
    name: "Menu5",
    image:
      "https://s3.eu-west-3.amazonaws.com/staging.uploads/da39cdd6cd2dc82b8372c346f9911860.jpg",
  },
];

const Restaurants = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useParams();

  const [menuIndex, setMenuIndex] = useState(0);
  const [menuAllList, setMenuAllList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuModel, setMenuModel] = useState([]);
  const [modelLoading, setModelLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const [restaurantId, setRestaurantId] = useState(
    urlParams?.id ? urlParams?.id : ""
  );
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantImg, setRestaurantImg] = useState("");
  const [restaurantLogo, setRestaurantLogo] = useState("");
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [viewArBtnTop, setViewArBtnTop] = useState(70);
  const [menuModelData, setMenuModelData] = useState(null);
  const [menuModelDataVisible, setMenuModelDataVisible] = useState(false);
  const [allergensModal, setAllergensModal] = useState(null);
  const [menuModelAR, setMenuModelAR] = useState(null);
  const [showAlertMsg, setShowAlertMsg] = useState(null);
  const [canActivateAr, setCanActivateAr] = useState(false);
  const [enterAframeAr, setEnterAframeAr] = useState(false);
  const [enterWebXrArMode, setEnterWebXrArMode] = useState(false);
  const [orderType, setOrderType] = useState(
    urlParams?.table_id ||
      location.pathname.split("/").pop().startsWith("table")
      ? TABLE
      : getTableNumberParam()
        ? TABLE
        : ""
  );
  const [tableNumber, setTableNumber] = useState(
    urlParams?.table_id ? urlParams?.table_id : getTableNumberParam()
  );
  const [restTemplate, setRestTemplate] = useState("temp2");
  const [addToCartExist, setAddToCartExist] = useState(false);
  const [vegNonveg, setVegNonveg] = useState("all");
  const [vrScreenModalVisible, setVrScreenModalVisible] = useState(false);
  const [menuTypeFilterList, setMenuTypeFilterList] = useState(null);
  const [menuModalUrlList, setMenuModalUrlList] = useState(null);
  const [showCategoryGrp, setShowCategoryGrp] = useState(true);
  const [categoryGrpPosition, setCategoryGrpPosition] = useState(
    CATEGORY_GRP_DEFAULT_POS
  );

  const menuNameRef = useRef();
  const menuModelRef = useRef();
  const progressCircleRef = useRef();
  const menuModalUrlListRef = useRef({});
  const abortControllerRef = useRef({});

  const onIdle = () => {
    if (!enterWebXrArMode) {
      onNextBtnClick();
    }
  };

  const { start, pause } = useIdleTimer({
    onIdle,
    throttle: 500000,
    timeout: 100000000,
  });

  useEffect(() => {
    setShowCategoryGrp(
      restaurantData?.themeColors?.arCategoryGrouping ?? false
    );
    setCategoryGrpPosition(
      restaurantData?.themeColors?.arCategoryGroupingPosition ??
      CATEGORY_GRP_DEFAULT_POS
    );
  }, [restaurantData]);

  useEffect(() => {
    if (enterWebXrArMode) {
      pause();
    } else {
      start();
    }
  }, [enterWebXrArMode]);

  useEffect(() => {
    pause();
  }, []);

  useEffect(() => {
    const list = props.menuList.filter(
      (x) =>
        x.modelId !== null &&
        x.modelId !== "" &&
        typeof x.extras?.menuType !== "undefined"
    );
    const menuTypeList = filterMenuType(list);
    setMenuTypeFilterList(menuTypeList);
  }, [props.menuList]);

  useEffect(() => {
    let data = checkRestTemplateData(restaurantId);
    setRestTemplate(data.TEMPLATE);

    let addCartExist =
      restaurantData && checkRestIsClosed(restaurantData?.openHours)
        ? false
        : restaurantData?.appType === APP_TYPE_GOPOSH ||
          restaurantData?.appType === APP_TYPE_BOTH
          ? (orderType === TABLE && restaurantData?.tableOrder && tableNumber) ||
          (orderType !== TABLE && restaurantData?.onlineOrder)
          : false;

    setAddToCartExist(addCartExist);
  }, [restaurantId, restaurantData]);

  useEffect(() => {
    getRestaurant();
  }, []);

  useEffect(() => {
    const menuId = getMenuIdParam();
    if ((props.lastViewedMenu || menuId) && menuList.length) {
      let findIndex = menuList.findIndex(
        (x) => x._id === (menuId ?? props.lastViewedMenu?._id)
      );
      if (findIndex >= 0) {
        setMenuIndex(findIndex);
      }
    }
  }, [menuList]);

  useEffect(() => {
    if (restaurantData?._id) {
      getCategoryList(restaurantData?._id);
    }
  }, [restaurantData?._id]);

  useEffect(() => {
    if (!enterAframeAr) {
      let info = menuModel.filter(
        (x) => x.menu_id === menuList?.[menuIndex]?.["_id"]
      )[0]?.model?.lightingInfo;
      if (menuModelRef.current) {
        if (menuModelRef.current.modelIsVisible) {
          progressCircleRef.current.style.display = "none";
          modelLoader("none");
          start();
        } else {
          progressCircleRef.current.style.display = "block";
          modelLoader("block");
          let interval = setInterval(progress, 100);

          function progress() {
            if (
              menuModelRef.current?.modelIsVisible ||
              !progressCircleRef.current
            ) {
              if (progressCircleRef.current) {
                progressCircleRef.current.style.display = "none";
                trackEvent(MENU_VIEW, {
                  restaurant_id: restaurantId,
                  item_id: menuList[menuIndex]?._id,
                });
                modelLoader("none");
                start();
              }
              clearInterval(interval);

              if (menuModelRef.current?.cameraOrbit) {
                let horizontal = 0;
                let vertical = 75;
                if (typeof info?.horizontalTiltAngle != "undefined") {
                  horizontal = info?.horizontalTiltAngle;
                }
                if (typeof info?.verticalTiltAngle != "undefined") {
                  vertical = info?.verticalTiltAngle;
                }
                menuModelRef.current.cameraOrbit = `${horizontal}deg ${vertical}deg 105%`;
              }

              if (menuModelRef.current?.cameraTarget) {
                if (typeof info?.verticalPosition != "undefined") {
                  menuModelRef.current.cameraTarget = `auto ${Number(
                    info?.verticalPosition
                  )}m auto`;
                }
              }

              if (menuModelRef.current?.exposure) {
                if (typeof info?.exposure != "undefined") {
                  menuModelRef.current.exposure = info?.exposure;
                }
              }

              let material = menuModelRef.current?.model?.materials[0];
              if (material?.emissiveTexture && info?.lighting?.length) {
                material.emissiveTexture.setTexture(
                  material.pbrMetallicRoughness.baseColorTexture.texture
                );
                material.setEmissiveFactor(info?.lighting);
              }

              menuModelRef.current?.addEventListener("ar-status", (e) => {
                if (e?.detail?.status === "session-started") {
                  setEnterWebXrArMode(true);
                }
              });
              setCanActivateAr(menuModelRef.current?.canActivateAR);
            } else {
              if (progressCircleRef.current) {
                progressCircleRef.current.style.display = "block";
                modelLoader("block");
              }
            }
          }
        }
        setViewArBtnTop(
          menuNameRef?.current?.offsetHeight
            ? menuNameRef?.current?.offsetHeight + 20
            : 70
        );
      } else {
        if (progressCircleRef.current) {
          progressCircleRef.current.style.display = "block";
          modelLoader("block");
        }
      }
    }
  }, [menuModelAR, enterAframeAr]);

  useEffect(() => {
    setMenuItemModelAr();
  }, [menuIndex, menuModel.length, vegNonveg]);

  useEffect(() => {
    if (restaurantId && getTableNumberParam()) {
      if (urlParams?.slug) {
        navigate("/r/" + urlParams?.slug + "/table/" + tableNumber, {
          replace: true,
        });
      } else {
        navigate("/restaurant/" + restaurantId + "/table/" + tableNumber, {
          replace: true,
        });
      }
    }
  }, [restaurantId]);

  useEffect(() => {
    if (orderType != TABLE) {
      props.updateTableData(null);
    }
  }, [orderType]);

  useEffect(() => {
    const menuId = menuList?.[menuIndex]?.["_id"];
    if (menuModalUrlList?.[menuId]) {
      setMenuModelAR(menuModalUrlList[menuId]);
    }
  }, [menuModalUrlList, menuIndex]);

  const setMenuItemModelAr = async () => {
    if (menuList.length) {
      const menuId = menuList?.[menuIndex]?.["_id"];
      let modelDetail = menuModel.filter((x) => x.menu_id === menuId)[0]?.model;
      if (modelDetail?.status === 4) {
        setMenuModelDataVisible(false);
        const menuIds = menuList
          .filter(
            (x, index) =>
              index >= menuIndex &&
              menuModel.find((y) => y.menu_id === x?._id)?.model?.status === 4
          )
          .map((x) => x?._id)
          .slice(0, 6)
          .filter(
            (id) => !Object.keys(menuModalUrlListRef.current).includes(id)
          );

        for (const id of menuIds) {
          const modelData = menuModel.find((x) => x.menu_id === id).model;
          await getMenuItemModelAr(
            modelData?.modelPath?.glb,
            id,
            id === menuIds?.[0]
          );
        }
      } else if (
        modelDetail?.status === 1 ||
        modelDetail?.status === 2 ||
        modelDetail?.status === 3
      ) {
        setMenuModelAR(null);
        if (enterWebXrArMode) {
          setTimeout(() => {
            onSetMenuModelData(menuList?.[menuIndex]);
          }, 1000);
        } else {
          onSetMenuModelData(menuList?.[menuIndex]);
        }
        if (enterAframeAr) {
          setEnterAframeAr(false);
        }
        if (enterWebXrArMode) {
          setEnterWebXrArMode(false);
        }
      }
    }
  };

  const getMenuItemModelAr = async (url, menuId, isAbort) => {
    try {
      let modelDataList = { ...menuModalUrlListRef?.current };
      if (typeof modelDataList?.[menuId] === "undefined") {
        if (isAbort) {
          Object.keys(abortControllerRef?.current)
            .filter((item) => abortControllerRef?.current[item])
            .forEach((item) => {
              abortControllerRef?.current[item].abort();
              abortControllerRef.current[item] = null;
            });
        } else {
          if (abortControllerRef?.current[menuId]) {
            abortControllerRef?.current[menuId].abort();
            abortControllerRef.current[menuId] = null;
          }
        }

        abortControllerRef.current = {
          ...abortControllerRef?.current,
          [menuId]: new AbortController(),
        };
        const signal = abortControllerRef?.current[menuId].signal;

        const config = { responseType: "blob", signal: signal };
        const response = await axios.get(url, config);
        if (response?.status === 200) {
          const url = URL.createObjectURL(response.data);
          menuModalUrlListRef.current = {
            ...modelDataList,
            [menuId]: url,
          };
          setMenuModalUrlList(menuModalUrlListRef.current);
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const modelLoader = (val) => {
    let loader = document.getElementById("progressLoader");
    if (loader) {
      loader.style.display = val;
    }
  };

  const getRestaurant = () => {
    let endpoint = restaurantId;
    if (urlParams?.slug) {
      endpoint = "byname/" + urlParams?.slug;
    }
    if (
      props.restData?._id == restaurantId ||
      (typeof props.restData?.slug != "undefined" &&
        props.restData?.slug == urlParams?.slug)
    ) {
      getRestaurantResponse(props.restData, false);
    }
    Api.getRestaurant(endpoint).then((response) => {
      if (response.success) {
        props.updateRestData(response.data);
        getRestaurantResponse(response.data, true);
      } else {
        onRestMenuNavigate();
        setIsLoading(false);
      }
    });
  };

  const getRestaurantResponse = (data, api_call) => {
    setRestaurantId(data._id);
    setRestaurantData(data);
    setRestaurantName(data.name);
    setRestaurantLogo(data.logo);
    if (data.images.length) {
      setRestaurantImg(data.images[0]);
    }
    if (props.restaurantDetail?._id == data?._id) {
      props.updateRestaurantDetail(data);
    }
    getMenuList(1, data?._id, menuList, api_call);
  };

  const getMenuList = (num, rest_id, menu_list = menuList, api_call) => {
    if (!api_call) {
      let list = JSON.parse(JSON.stringify(props.menuList)).filter(
        (x) => x?._idRestaurant == rest_id
      );
      if (list?.length) {
        getMenuListResponse(list, 1, menu_list, num, rest_id, api_call);
      }
    } else {
      if (!apiLoading) {
        setApiLoading(true);
        const filter = `?pageSize=${page_size}&pageNum=${num}&orderBy=order&orderByDir=asc&filter__idRestaurant=${rest_id}&filter_enabled=true`;

        Api.getMenuList(filter).then((response) => {
          if (response.success) {
            let data = response.data.rows;
            props.updateMenuList(data);
            getMenuListResponse(
              data,
              response.data.pages,
              menu_list,
              num,
              rest_id,
              api_call
            );
          } else {
            setIsLoading(false);
            setApiLoading(false);
          }
        });
      }
    }
  };

  const getMenuListResponse = (
    data,
    total_pages,
    menu_list,
    num,
    rest_id,
    api_call
  ) => {
    const list = data
      .filter((x) => x.modelId !== null && x.modelId !== "" && x?.enabled)
      .sort((a, b) => a?.modelOrder - b?.modelOrder);

    let total_list = [...menu_list, ...list];
    if (num == 1) {
      total_list = list;
    }
    setPageNo(num);
    setTotalPage(total_pages);
    if (list.length < 1 && num < total_pages) {
      getMenuList(num + 1, rest_id, total_list, api_call);
    } else if (
      list.length == 0 &&
      menu_list.length == 0
      // && num == response.data.pages
    ) {
      onRestMenuNavigate();
    } else {
      setMenuList(total_list);
      setMenuAllList(total_list);
      if (!menuModel.length && list.length > 0) {
        // if (list[0]["modelId"] != null && list[0]["modelId"] != "") {
        getMenuModel(list, rest_id, api_call);
        // } else {
        //   setIsLoading(false);
        //   setApiLoading(false);
        // }
      } else {
        setIsLoading(false);
        setApiLoading(false);
      }
    }
  };

  const getMenuModel = (menu_list, rest_id, api_call) => {
    if (!api_call) {
      if (props.modelList?.length && props.restData?._id === rest_id) {
        getMenuModelResponse(props.modelList, menu_list);
      }
    } else {
      setModelLoading(true);
      Api.getMenuModel("/all").then((response) => {
        if (response.success) {
          let data = response.data;
          props.updateModelList(data.rows);
          getMenuModelResponse(data.rows, menu_list);
        }
        setModelLoading(false);
        setApiLoading(false);
        setIsLoading(false);
      });
    }
  };

  const getMenuModelResponse = (data, menu_list) => {
    let model_list = [];
    if (data.length) {
      menu_list.map((item) => {
        let find_index = data.findIndex((y) => y._id == item.modelId);
        if (find_index >= 0) {
          model_list.push({
            menu_id: item._id,
            model: data[find_index],
          });
        }
      });
    }
    setMenuModel(model_list);
    setModelLoading(false);
    setApiLoading(false);
    setIsLoading(false);
  };

  const getCategoryList = async (rest_id) => {
    const filter = `?pageSize=30000&pageNum=1`;
    const response = await Api.getCategoryList(rest_id, filter);
    if (response?.success) {
      const data = response?.data?.rows?.sort((a, b) => a?.order - b?.order);
      props.updateCategoryList(data);
    }
  };

  const onPrevBtnClick = () => {
    if (!enterWebXrArMode) {
      setMenuModelAR(null);
    }

    if (enterAframeAr || enterWebXrArMode) {
      modelLoader("block");
    }

    if (customElements.get("model-viewer").modelCacheSize > 0) {
      customElements.get("model-viewer").modelCacheSize = 0;
    }
    let index = menuIndex > 0 ? menuIndex - 1 : 0;
    setMenuIndex(index);
  };

  const onNextBtnClick = () => {
    let index =
      menuIndex < menuList.length ? menuIndex + 1 : menuList.length - 1;

    if (index < menuList.length) {
      if (!enterWebXrArMode) {
        setMenuModelAR(null);
      }

      if (enterAframeAr || enterWebXrArMode) {
        modelLoader("block");
      }

      if (customElements.get("model-viewer").modelCacheSize > 0) {
        customElements.get("model-viewer").modelCacheSize = 0;
      }

      if (index == menuList.length - 1) {
        if (pageNo + 1 <= totalPage) {
          getMenuList(pageNo + 1, restaurantId);
        } else {
          setMenuIndex(index);
        }
      } else {
        setMenuIndex(index);
      }
    }
  };

  const onAddMenuItemToCart = (item, note, options, modifiers, count) => {
    const list = addMenuItemToCart(
      restaurantId,
      props.restaurantDetail?._id,
      props.cartItems,
      count,
      item,
      modifiers,
      options,
      note
    );
    if (restaurantId != props.restaurantDetail?._id) {
      props.updateRestaurantDetail(restaurantData);
    }
    props.updateCartItems(list);

    let menu_model = menuModel.filter(
      (x) => x.menu_id == menuList?.[menuIndex]?.["_id"]
    )[0]?.model;
    if (menu_model?.status == 4) {
      if (count > 0) {
        setMenuModelDataVisible(false);
      }
    } else if (
      menu_model?.status == 1 ||
      menu_model?.status == 2 ||
      menu_model?.status == 3
    ) {
      setShowAlertMsg("Added To Cart");
      setTimeout(() => {
        setShowAlertMsg(null);
      }, 1000);
    }
  };

  const onSetMenuModelData = (item) => {
    setMenuModelData(item);
    setMenuModelDataVisible(true);
  };

  const onShareBtnClick = async (data) => {
    let url =
      window.location.origin +
      "/restaurant/" +
      restaurantId +
      "?menu_id=" +
      data._id;
    if (navigator.share) {
      navigator
        .share({
          title: restaurantName,
          text: data?.name,
          url: url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigator.clipboard.writeText(url);
      setShowAlertMsg("Copied to Clipboard");
      setTimeout(() => {
        setShowAlertMsg(null);
      }, 1000);
    }
  };

  const onRestMenuNavigate = (categoryGroup = { selectedGroup: null }) => {
    if (menuList.length && menuIndex < menuList.length) {
      props.updateLastViewedMenu(menuList[menuIndex]);
    }

    let url = `/restaurant_menu/${restaurantId}`;
    if (location.pathname.split("/")[1] == "r") {
      url = `/r/menu/${urlParams?.slug}`;
    }
    if (orderType == TABLE) {
      if (tableNumber) {
        url = `${url}/table/${tableNumber}`;
      } else {
        url = `${url}/table`;
      }
    }
    if (categoryGroup.selectedGroup) localStorage.setItem("categoryGroupData", JSON.stringify(categoryGroup.selectedGroup));
    else localStorage.removeItem("categoryGroupData");
    navigate(url, {
      state: { previousPage: "ar_page", categoryGroupData: categoryGroup.selectedGroup ?? null },
    });
  };

  const onArIconClick = () => {
    if (document.querySelector("a[slot='ar-button']")) {
      document.querySelector("a[slot='ar-button']").click();
    }
  };

  const onWifiClick = () => {
    let showVrScreenModal = false;
    if (props.userData == null) {
      showVrScreenModal = true;
    } else {
      if (restaurantId == props.userData?._idRestaurant) {
        showVrScreenModal = false;
      } else {
        showVrScreenModal = true;
      }
    }
    if (showVrScreenModal) {
      setVrScreenModalVisible(true);
    }
  };

  const showPrevBtn =
    menuIndex !== 0
      ? menuModel.filter((x) => x.menu_id === menuList?.[menuIndex]?.["_id"])[0]
        ?.model?.status === 4 ||
      (allergensModal == null && menuModelData == null)
      : false;

  const showNextBtn =
    menuIndex < menuList.length - 1
      ? menuModel.find((x) => x.menu_id === menuList?.[menuIndex]?.["_id"])
        ?.model?.status === 4 ||
      (allergensModal === null && menuModelData === null)
      : false;

  const NextPrevBtnView = () => {
    if (restTemplate == "temp2") {
      return (
        <AppNextPrevBtnTemp2
          enterAframeAr={enterAframeAr}
          enterWebXrArMode={enterWebXrArMode}
          menuIcon={
            restaurantData?.bottomMenuIcon
              ? restaurantData.bottomMenuIcon
              : null
          }
          menuList={menuList}
          menuIndex={menuIndex}
          locale={props.locale}
          showPrevBtn={showPrevBtn}
          showNextBtn={showNextBtn}
          onNextBtnClick={onNextBtnClick}
          onPrevBtnClick={onPrevBtnClick}
          onRestMenuNavigate={onRestMenuNavigate}
        />
      );
    } else {
      return (
        <AppNextPrevBtnTemp1
          enterAframeAr={enterAframeAr}
          enterWebXrArMode={enterWebXrArMode}
          menuIcon={
            restaurantData?.bottomMenuIcon
              ? restaurantData.bottomMenuIcon
              : null
          }
          menuList={menuList}
          menuIndex={menuIndex}
          locale={props.locale}
          showPrevBtn={showPrevBtn}
          showNextBtn={showNextBtn}
          onNextBtnClick={onNextBtnClick}
          onPrevBtnClick={onPrevBtnClick}
          onRestMenuNavigate={onRestMenuNavigate}
        />
      );
    }
  };

  const ModelAR = React.useMemo(() => {
    let info = menuModel.filter(
      (x) => x.menu_id == menuList?.[menuIndex]?.["_id"]
    )[0]?.model?.lightingInfo;
    return menuModel.length && menuModelAR ? (
      <model-viewer
        ref={menuModelRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: 3,
          backgroundColor: "transparent",
        }}
        class={restTemplate == "temp2" ? "temp2-model-viewer" : ""}
        loading={"eager"}
        touch-action="none"
        exposure="1"
        src={menuModelAR}
        disable-tap
        // ios-src={ar_usdz}
        alt=""
        ar-modes="webxr scene-viewer quick-look"
        max-camera-orbit="Infinity 90deg auto"
        field-of-view={info?.zoom ? `${Number(info?.zoom)}deg` : "auto"}
        ar
        auto-rotate
        camera-controls
      >
        {/*<img*/}
        {/*  alt=""*/}
        {/*  src={BgImg[restTemplate]}*/}
        {/*  slot="poster"*/}
        {/*  style={{*/}
        {/*    height: "100%",*/}
        {/*    width: "100%",*/}
        {/*    // objectFit: "cover",*/}
        {/*    position: "absolute",*/}
        {/*  }}*/}
        {/*/>*/}
        <a style={{ display: "none" }} slot="ar-button"></a>
        <a
          slot="exit-webxr-ar-button"
          style={{ position: "absolute", top: 35, right: 25, zIndex: 6 }}
          onClick={() => setEnterWebXrArMode(false)}
        >
          <CloseIcon width={25} height={25} />
        </a>

        {/*{enterWebXrArMode ? <NextPrevBtnView /> : null}*/}

        {/*{enterWebXrArMode ? (*/}
        {/*  <div*/}
        {/*    className="progress-loader"*/}
        {/*    id="progressLoader"*/}
        {/*    style={{ display: "none" }}*/}
        {/*  >*/}
        {/*    <Loader height={{ height: window.innerHeight }} />*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </model-viewer>
    ) : null;
  }, [menuModelAR, enterWebXrArMode]);

  if (isLoading) {
    return (
      // <ARWebLoading/>
      <Loader height={{ height: window.innerHeight }} />
    );
  }

  // if (enterAframeAr) {
  //   return (
  //     <>
  //       <a-scene
  //         vr-mode-ui="enabled: false"
  //         arjs="sourceType: webcam; videoTexture: true; debugUIEnabled: false;"
  //         renderer="colorManagement: true;"
  //         xrextras-gesture-detector
  //       >
  //         {menuModelAR ? (
  //           <a-camera
  //             id="camera"
  //             position="0 8 8"
  //             raycaster="objects: .cantap"
  //             look-controls="touchEnabled: false"
  //             cursor="fuse: false; rayOrigin: mouse;"
  //           ></a-camera>
  //         ) : null}
  //
  //         {menuModelAR ? (
  //           <a-entity
  //             model-loaded
  //             id="arModelId"
  //             gltf-model={menuModelAR}
  //             className="cantap"
  //             xrextras-hold-drag
  //             xrextras-two-finger-rotate
  //             xrextras-pinch-scale
  //             scale="20 20 20"
  //             position="0 0 -1"
  //           >
  //             <a-ring
  //               id="ring"
  //               visible="false"
  //               position="0 -0.02 0"
  //               rotation="-90 0 0"
  //               color="#ffffff"
  //               radius-inner="0.14"
  //               radius-outer="0.15"
  //             ></a-ring>
  //           </a-entity>
  //         ) : null}
  //         <a-plane
  //           id="ground"
  //           rotation="-90 0 0"
  //           width="1000"
  //           height="1000"
  //           material="shader: shadow"
  //           shadow
  //         ></a-plane>
  //       </a-scene>
  //       <div
  //         className="progress-loader"
  //         id="progressLoader"
  //         style={{ display: "none" }}
  //       >
  //         <Loader height={{ height: window.innerHeight }} />
  //       </div>
  //       <Box
  //         sx={{ position: "absolute", top: 35, right: 25, zIndex: 6 }}
  //         onClick={() => setEnterAframeAr(false)}
  //       >
  //         <CloseIcon width={25} height={25} />
  //       </Box>
  //       <NextPrevBtnView />
  //     </>
  //   );
  // }

  return (
    <Grid item xs={12} className={`${styles1["main-view"]}`}>
      <Grid item xs={12} className={`${styles1["main-sub-view"]}`}>
        {restTemplate == "temp2" ? (
          <RestaurantTemplate2
            menuList={menuList}
            menuIndex={menuIndex}
            menuFullList={props.menuList}
            restaurantId={restaurantId}
            restaurantName={restaurantName}
            restaurantData={restaurantData}
            restaurantLogo={restaurantLogo}
            restTemplate={restTemplate}
            tableNumber={tableNumber}
            menuTypeList={menuTypeFilterList}
            enterAframeAr={enterAframeAr}
            enterWebXrArMode={enterWebXrArMode}
            canActivateAr={canActivateAr}
            menuNameRef={menuNameRef}
            progressCircleRef={progressCircleRef}
            viewArBtnTop={viewArBtnTop}
            locale={props.locale}
            userData={props.userData}
            showPrevBtn={showPrevBtn}
            showNextBtn={showNextBtn}
            setEnterAframeAr={setEnterAframeAr}
            setEnterWebXrArMode={setEnterWebXrArMode}
            setVrScreenModalVisible={setVrScreenModalVisible}
            categoryList={props.categoryList}
            showCategoryGrp={showCategoryGrp}
            categoryGrpPosition={categoryGrpPosition}
            categoryGrpList={categoryGrpList}
            onWifiClick={onWifiClick}
            onRestMenuNavigate={onRestMenuNavigate}
            onShareBtnClick={onShareBtnClick}
            onNextBtnClick={onNextBtnClick}
            onPrevBtnClick={onPrevBtnClick}
            onArIconClick={onArIconClick}
            modelAR={ModelAR}
            vegNonveg={vegNonveg}
            setVegNonveg={(val) => {
              let list = menuAllList.filter(
                (x) => x.extras?.menuType == val || val == "all"
              );
              if (list.length) {
                setMenuList(list);
                setMenuIndex(0);
                setMenuModelAR(null);
                setVegNonveg(val);
              } else {
                setShowAlertMsg("Items not found");
                setTimeout(() => {
                  setShowAlertMsg(null);
                }, 1000);
              }
            }}
            vrScreenModalVisible={vrScreenModalVisible}
          // onCloseVrScreenModal={() => setVrScreenModalVisible(false)}
          // onSubmitVrScreenModal={() => {
          //   setVrScreenModalVisible(false);
          //   // if (document.querySelector("a[slot='ar-button']")) {
          //   //   document.querySelector("a[slot='ar-button']").click();
          //   // }
          // }}
          />
        ) : (
          <RestaurantTemplate1
            menuList={menuList}
            menuIndex={menuIndex}
            menuFullList={props.menuList}
            restaurantId={restaurantId}
            restaurantName={restaurantName}
            restaurantImg={restaurantImg}
            restaurantLogo={restaurantLogo}
            restaurantData={restaurantData}
            restTemplate={restTemplate}
            viewArBtnTop={viewArBtnTop}
            enterAframeAr={enterAframeAr}
            enterWebXrArMode={enterWebXrArMode}
            canActivateAr={canActivateAr}
            menuNameRef={menuNameRef}
            progressCircleRef={progressCircleRef}
            locale={props.locale}
            userData={props.userData}
            showPrevBtn={showPrevBtn}
            showNextBtn={showNextBtn}
            setEnterAframeAr={setEnterAframeAr}
            setEnterWebXrArMode={setEnterWebXrArMode}
            setVrScreenModalVisible={setVrScreenModalVisible}
            onWifiClick={onWifiClick}
            onSetMenuModelData={onSetMenuModelData}
            onRestMenuNavigate={onRestMenuNavigate}
            onShareBtnClick={onShareBtnClick}
            onNextBtnClick={onNextBtnClick}
            onPrevBtnClick={onPrevBtnClick}
            onArIconClick={onArIconClick}
            modelAR={ModelAR}
          />
        )}
      </Grid>
      <ARWebMenuModal
        page={"main"}
        locale={props.locale}
        visible={menuModelDataVisible}
        data={menuModelData}
        menuList={props.menuList}
        menuModal={
          menuModel.filter(
            (x) => x.menu_id == menuList?.[menuIndex]?.["_id"]
          )[0]?.model
        }
        nextImg={
          (pageNo >= totalPage && menuList.length - 1 == menuIndex) ||
            (pageNo == 1 && totalPage == "")
            ? null
            : menuList?.[menuIndex + 1]?.["images"]?.[0]
              ? menuList?.[menuIndex + 1]?.["images"]?.[0]
              : null
        }
        prevImg={
          menuIndex == 0 ? null : menuList?.[menuIndex - 1]?.["images"]?.[0]
        }
        onNextBtnClick={onNextBtnClick}
        onPrevBtnClick={onPrevBtnClick}
        onAddMenuItemToCart={onAddMenuItemToCart}
        onClose={() => {
          setMenuModelDataVisible(false);
        }}
        onAllergensClick={() => {
          setAllergensModal(menuList?.[menuIndex]);
          // setMenuModelData(null);
        }}
        template={restTemplate}
        addToCartExist={addToCartExist}
      />
      <ARWebAllergensNutritions
        data={allergensModal}
        onClose={() => {
          let menu_model = menuModel.filter(
            (x) => x.menu_id == menuList?.[menuIndex]?.["_id"]
          )[0]?.model;
          setAllergensModal(null);
          if (menu_model?.status != 4) {
            onSetMenuModelData(menuList?.[menuIndex]);
          }
        }}
      />
      <Fade
        in={showAlertMsg != null}
        timeout={{
          enter: 1000,
          exit: 400,
        }}
      >
        <div style={styles.alertMsgDiv}>{showAlertMsg}</div>
      </Fade>
      <VrScreenModal
        visible={vrScreenModalVisible}
        restaurantId={restaurantId}
        restaurantName={restaurantName}
        restaurantData={restaurantData}
        onClose={() => setVrScreenModalVisible(false)}
        onSubmit={() => {
          setVrScreenModalVisible(false);
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.restaurantData.cartItems,
    restaurantDetail: state.restaurantData.restaurantDetail,
    locale: state.restaurantData.locale,
    selectedMenuData: state.restaurantData.selectedMenuData,
    lastViewedMenu: state.restaurantData.lastViewedMenu,
    userData: state.restaurantData.userData,
    restData: state.apiData.restData,
    categoryList: state.apiData.categoryList,
    menuList: state.apiData.menuList,
    modelList: state.apiData.modelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartItems: (data) => dispatch(updateCartItems(data)),
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updateLastViewedMenu: (data) => dispatch(updateLastViewedMenu(data)),
    updateRestData: (data) => dispatch(updateRestData(data)),
    updateCategoryList: (data) => dispatch(updateCategoryList(data)),
    updateMenuList: (data) => dispatch(updateMenuList(data)),
    updateModelList: (data) => dispatch(updateModelList(data)),
    updateTableData: (data) => dispatch(updateTableData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);

const styles = {
  arLogo: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 3,
  },
  alertMsgDiv: {
    backgroundColor: "#000",
    width: "fit-content",
    height: "fit-content",
    minWidth: 140,
    borderRadius: 3,
    padding: "10px 5px",
    zIndex: 3000,
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    margin: "auto",
    color: "#FFF",
    fontSize: 9,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
};
