import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { usePayment } from "../../context/PaymentContext";
import { clearCartData } from "../../../../redux/actions/restaurantDataActions";
import { useEffect, useState } from "react";
import CheckoutHeader from "../shared/CheckoutHeader";
import MiniInvoiceWidget from "../MiniInvoiceWidget";
import YourOrderWidget from "../YourOrderWidget";
import { formatCurrency } from "../../../../utils/utils";
import { TABLE } from "../../../../constants";
import i18next from "i18next";

const OrderSuccessPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentSuccessful, setPaymentSuccessful } = usePayment();
  const [orderUpdationError, setOrderUpdationError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const themeColors = useSelector(
    (state) => state.restaurantData?.restaurantDetail?.themeColors
  );

  const {
    orderSuccessBackBtnBgColor = null,
    orderSuccessBackBtnFontColor = null,
    orderSuccessBgColor = null,
    orderSuccessCardBgColor = null,
    orderSuccessCardFontColor = null,
    orderSuccessHeaderBgColor = null,
    orderSuccessHeaderFontColor = null,
    orderSuccessMiniInvoiceWidgetPrimaryBgColor = null,
    orderSuccessMiniInvoiceWidgetPrimaryFontColor = null,
    orderSuccessMiniInvoiceWidgetSecondaryBgColor = null,
    orderSuccessMiniInvoiceWidgetSecondaryFontColor = null,
  } = themeColors || {};

  const cartItems = location.state.cartItems;

  const orderDetails = location.state.orderData;

  const restaurantId = orderDetails?._idRestaurant;
  const tableNumber = orderDetails?.tableNumber;

  const charges = orderDetails?.charges?.chargeItems?.map((charge) => {
    if (charge.isActive && charge.orderType == orderDetails?.orderType)
      return {
        label: charge.name,
        value: charge.isPercentage
          ? (orderDetails?.subTotalCartPrice * charge.value) / 100
          : charge.value,
      };
  });
  let billingList = [
    {
      label: i18next.t("Subtotal"),
      value: orderDetails?.subTotalCartPrice,
    },
    ...charges,
  ];

  if (orderDetails?.tip) {
    billingList.push({
      label: i18next.t("Tip"),
      value: orderDetails?.tip,
    });
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_API_URL}stripe/${location.state.orderId}/refresh-payment`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          data.data.transactionStatus === "PAID" &&
          data.data?.orderType === TABLE
        ) {
          const existingOrders =
            JSON.parse(localStorage.getItem("pastOrders")) || [];

          const orderExists = existingOrders.some(
            (order) => order._id === data.data._id
          );

          if (!orderExists) {
            const newOrder = {
              ...data.data,
              tableNumber: tableNumber,
              cart: cartItems,
            };

            const updatedOrders = [...existingOrders, newOrder];

            localStorage.setItem("pastOrders", JSON.stringify(updatedOrders));
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOrderUpdationError(error.message);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [location.state.orderId, tableNumber]);

  const handleBackToRestaurantButtonClick = async () => {
    setPaymentSuccessful(false);
    dispatch(clearCartData());
    if (location.state.tableNumber) {
      navigate(
        `/restaurant_menu/${restaurantId}/table/${location.state.tableNumber}`
      );
    } else {
      navigate(`/restaurant_menu/${restaurantId}`);
    }
  };

  if (!paymentSuccessful) {
    return <Navigate to={`/restaurant_menu/${restaurantId}`} replace />;
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography ml={2}>Creating your order...</Typography>
      </Box>
    );
  }

  if (orderUpdationError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error" variant="h6">
          Error processing your order
        </Typography>
        <Typography>{orderUpdationError}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box
      className="device-width-view"
      display="flex"
      flexDirection="column"
      // alignItems="stretch"
      minHeight="100vh"
      overflow="auto"
      boxSizing="border-box"
      backgroundColor={orderSuccessBgColor ?? "#ffffff"}
      pb={10}
    >
      <Box className="device-width">
        <CheckoutHeader
          headerTitle="Order Overview"
          disableBackButton
          bgColor={orderSuccessHeaderBgColor}
          fontColor={orderSuccessHeaderFontColor}
        />

        <MiniInvoiceWidget
          orderData={location.state.orderData}
          tickIconBgColor={orderSuccessBgColor}
          primaryBgColor={orderSuccessMiniInvoiceWidgetPrimaryBgColor}
          primaryFontColor={orderSuccessMiniInvoiceWidgetPrimaryFontColor}
          secondaryBgColor={orderSuccessMiniInvoiceWidgetSecondaryBgColor}
          secondaryFontColor={orderSuccessMiniInvoiceWidgetSecondaryFontColor}
        />

        <YourOrderWidget
          orderData={location.state.orderData}
          cartItems={cartItems}
          bgColor={orderSuccessCardBgColor}
          fontColor={orderSuccessCardFontColor}
        />

        <Box
          p={2}
          m={1}
          sx={{
            mx: 1,
            mt: 3,
            p: 2,
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "15px",
            backgroundColor: orderSuccessCardBgColor ?? "#FFF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Typography
              sx={{
                ...styles.thankyouText,
                color: orderSuccessCardFontColor ?? "#212529",
              }}
            >
              Your Bill
            </Typography>

            <Box className={styles.listView}>
              <Typography
                className={styles.totalVal}
                sx={{
                  fontFamily: "GilroyBold",
                  fontSize: 20,
                  color: orderSuccessCardFontColor ?? "#212529",
                }}
              >
                {formatCurrency(orderDetails?.totalAmount)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
            }}
          >
            {billingList.map((item, i) =>
              item?.value > 0 ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 0.5,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "GilroyRegular",
                      fontSize: 13,
                      color: orderSuccessCardFontColor ?? "#212529",
                    }}
                  >
                    {item?.label}
                  </span>
                  <strong
                    style={{
                      fontFamily: "GilroyRegular",
                      fontSize: 13,
                      color: orderSuccessCardFontColor ?? "#212529",
                    }}
                  >
                    {formatCurrency(item?.value)}
                  </strong>
                </Typography>
              ) : null
            )}
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 0.5,
              }}
            >
              <span
                style={{
                  fontFamily: "GilroyRegular",
                  fontSize: 18,
                  color: orderSuccessCardFontColor ?? "#212529",
                }}
              >
                Total
              </span>
              <strong
                style={{
                  fontFamily: "GilroyRegular",
                  fontSize: 18,
                  color: orderSuccessCardFontColor ?? "#212529",
                }}
              >
                {formatCurrency(orderDetails?.totalAmount)}
              </strong>
            </Typography>
          </Box>
        </Box>

        <Button
          className="order-success-btn-device-width"
          variant="contained"
          color="primary"
          onClick={handleBackToRestaurantButtonClick}
          sx={{
            position: "fixed",
            bottom: "20px",
            backgroundColor: orderSuccessBackBtnBgColor ?? "#212121",
            cursor: "pointer",
            textTransform: "capitalize",
            color: orderSuccessBackBtnFontColor ?? "#FFF",
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: "1px",
            borderRadius: "10px",
            height: 54,
            mx: 1,
            "&:hover": {
              backgroundColor: `${
                orderSuccessBackBtnBgColor ?? "#212121"
              } !important`,
            },
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default OrderSuccessPage;

const styles = {
  dataView: {
    backgroundColor: "#FFF",
    borderRadius: "15px",
  },
  topView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  thankyouText: {
    color: "#212529",
    fontSize: 23,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  orderPlacedText: {
    color: "#212529",
    fontSize: 16,
    fontFamily: "GilroyMedium",
    textAlign: "center",
  },
  sendEmailText: {
    color: "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  timeText: {
    color: "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  addressTitle: {
    color: "#212529",
    fontSize: 15,
    fontFamily: "GilroyBlack",
  },
  titleText: {
    color: "#212529",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  dataPickupView: {
    backgroundColor: "#FFF",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  descText: {
    color: "#212529",
    fontSize: 50,
    lineHeight: "50px",
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  lottieView: {
    height: "40%",
    display: "flex",
    justifyContent: "center",
  },
};
