import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./CategoryGrpList.module.css";
import { getUngroupedCategories } from "../utils/categoryUtils";

export const CategoryGrpList = ({
  list = [],
  allCategories = [],
  height,
  onCategorySelect,
  onGroupSelect,
}) => {
  // Get categories not in any group
  const ungroupedCategories = getUngroupedCategories(allCategories, list);

  // Create a group for ungrouped categories if any exist
  const ungroupedGroup =
    ungroupedCategories.length > 0
      ? {
          name: "Other Categories",
          categories: ungroupedCategories,
          img: { image: null },
        }
      : null;

  // Combine regular groups with ungrouped
  const allGroups = [...list, ...(ungroupedGroup ? [ungroupedGroup] : [])];

  return (
    <Box className={styles["main-view"]} sx={{ height, paddingRight: "10px" }}>
      {allGroups.map((group, index) => (
        <Box
          key={index}
          className={styles["main-item-view"]}
          onClick={() => onGroupSelect(group)}
          sx={{
            width: group.imageWidth ? `${group.imageWidth}px` : "120px",
          }}
        >
          <Box className={styles["item-view"]}>
            <Box className={styles["img-view"]}>
              {group.img?.image ? (
                <img
                  alt={group.name}
                  src={group.img.image}
                  className={styles["item-img"]}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Box className={styles["placeholder-img"]}>
                  {group.name.charAt(0)}
                </Box>
              )}
            </Box>
            <Box className={styles["bg-shade"]} />
            <Typography className={styles["item-text"]}>
              {group.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
